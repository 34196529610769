import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';

import { AppRoutingModule } from './app-routing.module';
import { PageLayoutModule } from './shared/page-layout/page-layout.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { httpInterceptorProviders } from './shared/http-interceptors';
import { NgxFileDropModule } from 'ngx-file-drop';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { MatImporterModule } from './shared/mat-importer/mat-importer.module';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { LoginComponent } from './pages/login/login.component';
import { SignupComponent } from './pages/signup/signup.component';
import { ProductsComponent } from './pages/products/products.component';
import { DipSliderComponent } from './shared/dip-slider/dip-slider.component';
import { DipTabulatorComponent } from './shared/dip-tabulator/dip-tabulator.component';
import { ProductDialogComponent } from './pages/products/product-dialog/product-dialog.component';
import { SMDProductDialogComponent } from './pages/products/smdproduct-dialog/smdproduct-dialog.component';
import { UsersComponent } from './pages/users/users.component';
import { UserDetailComponent } from './pages/users/user-detail/user-detail.component';
import { PartRequestsComponent } from './pages/products/part-requests/part-requests.component';
import { PartRequestsFormDialogComponent } from './pages/products/part-requests/part-requests-form-dialog/part-requests-form-dialog.component';
import { PartRequestsFilterComponent } from './pages/products/part-requests/part-requests-filter/part-requests-filter.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { ChangePasswordDialogComponent } from './pages/user-profile/change-password-dialog/change-password-dialog.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { PasswordResetRequestComponent } from './pages/password-reset-request/password-reset-request.component';
import { NewsAndUpdatesComponent } from './shared/news-and-updates/news-and-updates.component';
import { OrganizationsComponent } from './pages/organizations/organizations.component';
import { OrganizationsFormDialogComponent } from './pages/organizations/organizations-form-dialog/organizations-form-dialog.component';
import { InquiryComponent } from './pages/inquiry/inquiry.component';
import { UploadFileComponent } from './pages/products/upload-file/upload-file.component';
import { SearchDialogComponent } from './pages/products/upload-file/search-dialog/search-dialog.component';
import { RowPopupComponent } from './pages/products/upload-file/row-popup/row-popup.component';
import { NewsComponent } from './pages/news/news.component';
import { DeleteDialogComponent } from './pages/news/delete-dialog/delete-dialog.component';
import { SafePipe } from './pages/news/safe.pipe';
import { TermsComponent } from './pages/terms/terms.component';
import { SpecifyDialogComponent } from './pages/products/upload-file/specify-dialog/specify-dialog.component';
import { InfoDialogComponent } from './pages/products/upload-file/info-dialog/info-dialog.component';
import { PartRequestInfoDialogComponent } from './pages/products/part-requests/info-dialog/part-requests-info-dialog.component';
import { CreatePasswordComponent } from './pages/signup/create-password/create-password.component';
import { CustomSnackBarComponent } from './pages/products/upload-file/custom-snack-bar/custom-snack-bar.component';
import { PreviousRouteService } from 'src/app/repositories/users-repo/previous-page.service.spec';






@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    LoginComponent,
    SignupComponent,
    ProductsComponent,
    DipSliderComponent,
    DipTabulatorComponent,
    ProductDialogComponent,
    SMDProductDialogComponent,
    UsersComponent,
    UserDetailComponent,
    PartRequestsComponent,
    PartRequestsFormDialogComponent,
    PartRequestsFilterComponent,
    UserProfileComponent,
    ChangePasswordDialogComponent,
    PageNotFoundComponent,
    PasswordResetComponent,
    PasswordResetRequestComponent,
    NewsAndUpdatesComponent,
    OrganizationsComponent,
    OrganizationsFormDialogComponent,
    InquiryComponent,
    UploadFileComponent,
    SearchDialogComponent,
    RowPopupComponent,
    NewsComponent,
    DeleteDialogComponent,
    SafePipe,
    TermsComponent,
    SpecifyDialogComponent,
    InfoDialogComponent,
    PartRequestInfoDialogComponent,
    CreatePasswordComponent,
    CustomSnackBarComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    PageLayoutModule,
    BrowserAnimationsModule,
    DragDropModule,
    MatImporterModule,
    NgxFileDropModule,
    AngularEditorModule,
    FormsModule,
    InfiniteScrollModule,
    MatCheckboxModule,
    RecaptchaModule
  ],
  providers: [
    httpInterceptorProviders,
    PreviousRouteService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
